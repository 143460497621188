body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@page {
  size: 210mm 297mm;
  margin: 27mm 16mm;
}

html {
  font-size: 10px;
}
body {
  font-size: 1.6rem;
}
h1 {
  font-size: 3.2rem;
}
h2 {
  font-size: 2.4rem;
}
h3 {
  font-size: 1.872rem;
}

@media print {
  html {
    font-size: 8px;
  }
}

